import React, { useState, useEffect, useCallback, useRef } from 'react';
import './playlistsetting.css';
import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../redux/apiSlice';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { MdOutlineStayCurrentLandscape } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import VideoPortraitCard from '../video-gallery/VideoPortraitCard';
import VideoLandscapeCard from '../video-gallery/VideoLandscapeCard';
import { IoIosPhonePortrait } from 'react-icons/io';
import { FallingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Productdetailselected from './Productdetailselected';
import { toast } from 'react-toastify';
import PDPTable from './PDPTable';
import PDpIntegrateModal from './PDpIntegrateModal';
import './playlistsetting.css';
import { allApiInstance } from '../../axiosInstances';

const Productdetailpage = () => {
  const [productUrl, setProductUrl] = useState('');
  const [pdpData, setPdpData] = useState([]);
  const [layout, setLayout] = useState('layout1');
  const [filterProduct, setFilterProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [landscapevideoData, setLandscapevideoData] = useState([]);
  const [portraitvideoData, setPortraitvideoData] = useState([]);
  const [queryText, setQueryText] = useState('');
  const [filterOrientation, setFilterOrientation] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [showCheckboxes, setShowCheckboxes] = useState(true);
  const [selectedCards, setSelectedCards] = useState([]);
  const [playinVideoId, setPlayinVideoId] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [landscapeVideos, setlandscapeVideos] = useState();
  const [filterProductpdp, setFilterProductpdp] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [modal, setModal] = useState(false);
  const [pdpID, setPDPID] = useState(null);
  const [error, setError] = useState('');
  // const [modalData, setModalData] = useState({})

  const Videos = (selectedCards) => {
    const filteredVideosData = portraitvideoData.filter((video) =>
      selectedCards.includes(video.video_id)
    );
    const landscapeVideosData = landscapevideoData.filter((video) =>
      selectedCards.includes(video.video_id)
    );

    setFilteredVideos(
      filteredVideosData.length > 0 ? filteredVideosData : landscapeVideosData
    );
    return filteredVideosData.length > 0
      ? filteredVideosData
      : landscapeVideosData;
  };

  const goToNextStep = () => {
    if (selectedCards.length > 0) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.warning('Plese select at least 1 video');
    }
  };
  const goTopreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '350px',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
  };

  useEffect(() => {
    fetchDataproduct();
  }, []);

  const debouncedFetch = useCallback(async () => {
    try {
      if (productUrl.includes('http')) {
        setError('');
        setLoading(true);
        await allApiInstance
          .post(
            `/getSwirlShopifyPdpInfoByBrandIdAndUrl`,
            {
              videoUrl: productUrl
                ? productUrl
                : filterProductpdp.externalLinks,
              brandId: user.brand_id,
            }
          )
          .then((response) => {
            if (response.data.resp) {
              const obj = response.data.resp;
              const videoIds = obj.videoId;
              const arrayOfIds = videoIds.split(',');
              const ids = arrayOfIds.map((el) => {
                return parseInt(el.trim());
              });
              setPDPID(response.data.resp.id);
              setEditMode(true);
              setSelectedCards(ids);
              Videos(ids);
              setLoading(false);
            } else {
              setEditMode(false);
              setLoading(false);
            }
          });
      } else {
        if (productUrl.length > 0) {
          setError('Enter valid product url');
        }
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setLoading(false);
    }
  }, [productUrl, user.brand_id, filterProductpdp]);

  useEffect(() => {
    if (filteredVideos.length > 0 && editMode) {
      const checkPdpType = filteredVideos[0].is_landscape;

      if (checkPdpType == 1) {
        setFilterOrientation(1);
      } else {
        setFilterOrientation(2);
      }
    }
  }, [filteredVideos]);

  const setlandscape = () => {
    if (filteredVideos?.length > 0) {
      const check = filteredVideos[0].is_landscape;

      if (check === 0 || check === '0') {
        setFilterOrientation(2);
      } else {
        setFilterOrientation(1);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        productUrl !== '' ||
        filterProductpdp !== null ||
        filterProductpdp !== undefined
      ) {
        debouncedFetch();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [debouncedFetch]);

  useEffect(() => {
    setlandscape();
  }, [filteredVideos]);

  const fetchDataproduct = async () => {
    setLoading(true);
    try {
      const productsRes = await allApiInstance.get(
        `/getProductsByBrandId?brandId=${user.brand_id}`
      );
      setProducts(productsRes.data.products || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      await allApiInstance
        .get(
          `/getVideosByVideoOrientationAndBrandId?brandId=${user.brand_id}&videoOrientation=1`
        )
        .then((res) => {
          if (res.data) {
            setPortraitvideoData(res.data.swirls);
          }
        });
      await allApiInstance
        .get(
          `/getVideosByVideoOrientationAndBrandId?brandId=${user.brand_id}&videoOrientation=0`
        )
        .then((res) => {
          if (res.data) {
            setLandscapevideoData(res.data.swirls);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterOrientation]);

  useEffect(() => {
    if (productUrl.length > 0 && productUrl.includes('http')) {
      setFilterProductpdp(null);
    }
  }, [productUrl]);

  const getPdpData = async () => {
    try {
      await allApiInstance
        .get(
          `/getAllPdpInfoByBrandId?brandId=${user.brand_id}`
        )
        .then((res) => {
          if (res.data.resp) {
            setPdpData(res.data.resp);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPdpData();
  }, [user]);

  const handleUrlChange = async (e) => {
    setProductUrl(e.target.value);
    setSelectedCards([]);
    setFilteredVideos([]);
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  // const handleCheckboxChange = (id) => {
  //     setSelectedCards((prevSelected) => {

  //         const updatedSelected = prevSelected.includes(id)
  //             ? prevSelected.filter((selectedId) => selectedId !== id)
  //             : [...prevSelected, id];

  //         const data = Videos(updatedSelected);

  //         return updatedSelected;

  //     });
  // };

  const handleCheckboxChange = (id) => {
    setSelectedCards((prevSelected) => {
      let updatedSelected;
      if (prevSelected.includes(id)) {
        updatedSelected = prevSelected.filter(
          (selectedId) => selectedId !== id
        );
      } else {
        if (prevSelected.length < 4) {
          updatedSelected = [...prevSelected, id];
        } else {
          toast.warn('Max limit exceeded');
          return prevSelected;
        }
      }

      // Call the Videos function with the updated selected cards
      const data = Videos(updatedSelected);

      return updatedSelected;
    });
  };

  function extractFieldAndJoin(array, fieldName) {
    // Check if the array is not empty
    if (array.length === 0) {
      return ''; // Return an empty string if the array is empty
    }

    // Extract the specified field from each object
    const values = array.map((obj) => obj[fieldName]);

    // Join the values into a comma-separated string
    const result = values.join(',');

    return result;
  }
  const options = [
    { value: '1', label: 'Portrait (9:16)' },
    { value: '2', label: 'Landscape (16:9)' },
  ];
  const customStyless = {
    option: (provided, state) => {
      const isLast = state.options.indexOf(state.data) === state.options.length;
      return {
        ...provided,
        padding: 10,
        borderBottom: isLast ? 'none' : '1px solid rgba(232, 232, 232, 1)',
        backgroundColor: state.isSelected ? '#e0e0e0' : '',
        color: '',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
      };
    },
    control: (provided) => ({
      ...provided,
      width: 220,
      borderRadius: '7px',
      // color: 'rgba(162, 162, 164, 1)'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '7px',
      border: ' rgba(209, 209, 210, 1)',
    }),
  };

  const handlePDpSubmit = async () => {
    try {
      if (editMode) {
        await allApiInstance
          .patch('/updatePdpInfoBySwirlShopifyPdpId', {
            payload: {
              brandId: user.brand_id,
              videoId: extractFieldAndJoin(filteredVideos, 'video_id'),
              productLink: productUrl
                ? productUrl
                : filterProductpdp.externalLinks,
              id: pdpID,
            },
          })
          .then((res) => {
            if (res.data.resp) {
              setModal(true);
              getPdpData();
              setPDPID(null);
              setFilterOrientation(1);
            }
            toast.success('Pdp link updated');
          })
          .catch((err) => {
            toast.error('Something went wrong!');
            console.log(err);
          });
      } else {
        await allApiInstance
          .post('/insertPdpInfoInSwirlShopifyPdp', {
            payload: {
              brandId: user.brand_id,
              videoId: extractFieldAndJoin(filteredVideos, 'video_id'),
              productLink: productUrl
                ? productUrl
                : filterProductpdp.externalLinks,
            },
          })
          .then((res) => {
            if (res.data.resp) {
              setModal(true);
              getPdpData();
              setFilterOrientation(1);
            }

            toast.success('Pdp link created');
          })
          .catch((err) => {
            toast.error('Something went wrong!');
            console.log(err);
          });
      }
    } catch (error) {
      toast.error('Something went wrong! ');
      console.log(error);
    }
  };
  const fetchData2 = async () => {
    try {
      const [landscapeRes, portraitRes] = await Promise.all([
        allApiInstance.get(
          `/getAllLandscapeVideoByBrandId?brandId=${user.brand_id}&searchQuery=${queryText}&productId=${filterProduct?.productId}`
        ),
        allApiInstance.get(
          `/getAllPortraitVideoByBrandId?brandId=${user.brand_id}&searchQuery=${queryText}&productId=${filterProduct?.productId}`
        ),
        getPdpData(),
      ]);

      setLandscapevideoData(landscapeRes.data.swirls);

      setPortraitvideoData(portraitRes.data.swirls);
    } catch (error) {
      console.log(error);
    }
  };
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchData2();
    }, 400);

    return () => clearTimeout(timeoutRef.current);
  }, [queryText, filterProduct]);
  // useEffect(() => {
  //     if (filterProduct) {
  //         if (filterOrientation == 1) {
  //             const data = portraitvideoData.filter((el) => {

  //                 if (el.product_id) {

  //                     let ids = el?.product_id?.split(",")

  //                     let processed_elements = ids.map(element => parseInt(element));

  //                     return processed_elements.includes(filterProduct.productId)
  //                 }

  //             })
  //             setPortraitvideoData(data)
  //         } else {
  //             const data = landscapevideoData.filter((el) => {
  //                 if (el.product_id) {
  //                     let ids = el?.product_id?.split(",")
  //                     let processed_elements = ids.map(element => parseInt(element));

  //                     return processed_elements.includes(filterProduct.productId)
  //                 }
  //             })
  //             setLandscapevideoData(data)
  //         }
  //     }

  //     else {
  //         fetchData()

  //     }

  // }, [filterProduct, filterOrientation])

  if (loading) {
    return (
      <div
        className="w-100 d-grid "
        style={{ placeItems: 'center', height: '70vh' }}
      >
        <FallingLines
          color="#EEEE77"
          width="100"
          visible={true}
          ariaLabel="falling-circles-loading"
        />
      </div>
    );
  }

  return (
    <div className="h-100">
      {currentStep === 1 && (
        <div className="h-100">
          <div className="row">
            <div className="col-5">
              <label className="fs-14">Enter product url</label>
              <input
                type="text"
                className="mt-2 d-flex form-control"
                placeholder="Enter url"
                name="productUrl"
                value={productUrl}
                onChange={handleUrlChange}
              />
              {error.length > 0 && (
                <small className="text-danger ms-2">{error}</small>
              )}
            </div>
            <div
              className="col-2 m-2"
              style={{
                fontSize: '16px',
                alignContent: 'end',
                flexBasis: 'content',
              }}
            >
              OR
            </div>
            <div className="col-5" style={{ zIndex: '10' }}>
              <label className="fs-14">Select product</label>
              <Select
                className="justify-content-end mt-2"
                options={products}
                value={filterProductpdp}
                getOptionLabel={(option) => option.productTitle}
                getOptionValue={(option) => option.productId}
                placeholder="Search by product"
                styles={customStyles}
                isClearable={true}
                onChange={async (e) => {
                  setFilterProductpdp(e);
                  setSelectedCards([]);
                  setFilteredVideos([]);
                  setProductUrl('');
                }}
              />
            </div>
          </div>
          {(productUrl || filterProductpdp?.productTitle) &&
            productUrl.includes('http') ? (
            <div style={{ height: 'calc(100% - 60px)' }}>
              <div className="row mt-2 mb-2">
                <label className="fs-14">Layout</label>
                <div className="d-flex mt-2">
                  <div className="form-check me-3">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="layoutStyle"
                      id="layout3"
                      value="layout3"
                      checked={layout === 'layout3'}
                      onChange={(e) => setLayout(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="layout3">
                      Picture in picture
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="layoutStyle"
                      id="layout1"
                      value="layout1"
                      checked={layout === 'layout1'}
                      onChange={(e) => setLayout(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="layout1">
                      Carousel
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2 justify-content-end ">
                <form className="d-flex justify-content-end gap-2">
                  <Select
                    options={options}
                    styles={customStyless}
                    value={options.find(
                      (option) => option.value == filterOrientation
                    )}
                    onChange={(selectedOption) => {
                      setFilterOrientation(selectedOption.value);
                      setSelectedCards([]);
                    }}
                  />
                  <Select
                    className="justify-content-end"
                    options={products}
                    getOptionLabel={(option) => option.productTitle}
                    getOptionValue={(option) => option.productId}
                    placeholder="Search by product"
                    styles={customStyles}
                    isClearable={true}
                    onChange={async (e) => {
                      setFilterProduct(e);
                    }}
                  />

                  <InputGroup
                    className="mb-3"
                    style={{
                      border: '1px solid rgba(209, 209, 210, 1)',
                      borderRadius: '7px',
                    }}
                  >
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ backgroundColor: 'white', border: 'none' }}
                    >
                      <i className="ri-search-line"></i>
                    </InputGroup.Text>
                    <Form.Control
                      style={{ border: 'none', maxWidth: '230px' }}
                      type="search"
                      className="form-control"
                      placeholder=" Search video..."
                      onChange={(e) => {
                        setQueryText(e.target.value);
                      }}
                    />
                  </InputGroup>
                </form>
              </div>
              <div style={{ height: 'calc(100% - 192px)', overflowY: 'auto' }}>
                <div>
                  {filterOrientation == 1 && (
                    <div>
                      {portraitvideoData.length > 0 && (
                        <>
                          <div className="d-block">
                            {/* <span className='p-3' style={{ borderBottom: "1px solid #17171C" }}>
                                                        <IoIosPhonePortrait size={30} style={{ marginLeft: "-10px" }} />  <span className='ms-1'>Portrait</span>
                                                    </span> */}
                          </div>
                          <div className="d-flex w-100 flex-wrap mt-3">
                            {portraitvideoData
                              .sort((a, b) => {
                                const aSelected = selectedCards?.includes(
                                  a.video_id
                                );
                                const bSelected = selectedCards?.includes(
                                  b.video_id
                                );
                                return bSelected - aSelected; // Moves selected videos to the top
                              })
                              .map((el, index) => (
                                <VideoPortraitCard
                                  key={index}
                                  setPlayinVideoId={setPlayinVideoId}
                                  playinVideoId={playinVideoId}
                                  toggleCheckboxes={toggleCheckboxes}
                                  setLoading={setLoading}
                                  selectedCards={selectedCards}
                                  handleCheckboxChange={handleCheckboxChange}
                                  el={el}
                                  showCheckboxes={showCheckboxes}
                                  index={index}
                                />
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                {filterOrientation == 2 && (
                  <div className="mt-4">
                    {landscapevideoData.length > 0 && (
                      <>
                        <div className="d-block">
                          {/* <span className='p-3' style={{ borderBottom: "1px solid #17171C" }}>
                                                    <MdOutlineStayCurrentLandscape size={30} style={{ marginLeft: "-10px" }} />  <span className='ms-1'>Landscape</span>
                                                </span> */}
                        </div>
                        <div className="d-flex w-100 flex-wrap mt-3">
                          {landscapevideoData
                            .sort((a, b) => {
                              const aSelected = selectedCards?.includes(
                                a.video_id
                              );
                              const bSelected = selectedCards?.includes(
                                b.video_id
                              );
                              return bSelected - aSelected; // Moves selected videos to the top
                            })
                            .map((el, index) => (
                              <VideoLandscapeCard
                                key={index}
                                setPlayinVideoId={setPlayinVideoId}
                                playinVideoId={playinVideoId}
                                setLoading={setLoading}
                                toggleCheckboxes={toggleCheckboxes}
                                selectedCards={selectedCards}
                                handleCheckboxChange={handleCheckboxChange}
                                el={el}
                                showCheckboxes={showCheckboxes}
                                index={index}
                              />
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className=" col-1 mt-2 ms-3 d-flex gap-2 mb-5">
                <button
                  className="btn btn-light btn-hover-white"
                  onClick={() => {
                    setEditMode(false);
                    setProductUrl('');
                    setSelectedCards([]);
                    setFilterProductpdp(null);
                    setFilterOrientation(1);
                  }}
                  style={{ color: 'rgba(51, 68, 153, 1)' }}
                >
                  Cancel
                </button>
                <button className="btn btn-dark" onClick={goToNextStep}>
                  Next
                </button>
              </div>
            </div>
          ) : (
            <>
              <PDPTable
                setLoading={setLoading}
                setEditMode={setEditMode}
                setProductUrl={setProductUrl}
                data={pdpData}
                setPdpData={setPdpData}
                setModal={setModal}
              />
            </>
          )}
        </div>
      )}
      {currentStep === 2 && (
        <Productdetailselected
          filteredVideos={filteredVideos}
          setFilteredVideos={setFilteredVideos}
          setlandscapeVideos={setlandscapeVideos}
          landscapeVideos={landscapeVideos}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          portraitvideoData={portraitvideoData}
          landscapevideoData={landscapevideoData}
          filterOrientation={filterOrientation}
          setfilterOrientation={setFilterOrientation}
          playinVideoId={playinVideoId}
          setPlayinVideoId={setPlayinVideoId}
          setLoading={setLoading}
        />
      )}
      {currentStep === 2 && (
        <div className=" d-flex ms-3">
          <button
            className="btn btn-light btn-hover-white me-2"
            style={{ color: ' rgba(51, 68, 153, 1)' }}
            onClick={goTopreviousStep}
          >
            Back
          </button>
          <button
            className="btn btn-dark"
            onClick={() => {
              handlePDpSubmit();
            }}
          >
            {editMode ? 'Update' : 'Submit'}
          </button>
        </div>
      )}
      <PDpIntegrateModal
        show={modal}
        handleClose={() => {
          setModal(false);

          setCurrentStep(1);
          setSelectedCards([]);
          setProductUrl('');
          setFilterProductpdp([]);
          setFilteredVideos([]);
        }}
        setCurrentStep={setCurrentStep}
      />
    </div>
  );
};

export default Productdetailpage;
