import React, { useEffect, useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddVideos from '../components/playlist/AddVideos';
import ManageVideos from '../components/playlist/ManageVideos';
import Createplaylist from '../components/playlist/createPlaylist';
import { FallingLines } from 'react-loader-spinner';
import './playlist.css';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import PlaylistcodeModal from '../components/playlist/PlaylistcodeModal';
import EditVideos from '../components/playlist/EditVideos';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Alert } from 'react-bootstrap';
import { setLoading } from '../redux/apiSlice';
import { allApiInstance } from '../axiosInstances';

const Step1 = ({
  tab,
  setTab,
  setSelectedVideos,
  selectedVideos,
  loadingOverlay,
  setLandscapeOffset,
  queryText,
  setFilterProduct,
  fetchData,
  fetchData2,
  filterProduct,
  setQueryText,
  landscapevideoData,
  setTableedit,
  portraitvideoData,
  isPortrait,
  isLandscape,
  setPortraitOffset,
  viewMoreLandscape,
  viewMorePortrait,
}) => {
  const { previousStep, nextStep } = useWizard();

  return (
    <div>
      <EditVideos
        setSelectedVideos={setSelectedVideos}
        selectedVideos={selectedVideos}
        videoData={isPortrait ? portraitvideoData : landscapevideoData}
        isPortrait={isPortrait}
        isLandscape={isLandscape}
        viewMoreLandscape={viewMoreLandscape}
        viewMorePortrait={viewMorePortrait}
        setPortraitOffset={setPortraitOffset}
        setLandscapeOffset={setLandscapeOffset}
        setQueryText={setQueryText}
        fetchData={fetchData}
        fetchData2={fetchData2}
        filterProduct={filterProduct}
        queryText={queryText}
        setFilterProduct={setFilterProduct}
        loadingOverlay={loadingOverlay}
        tab={tab}
        setTab={setTab}
      />

      <button
        className="btn btn-light mt-2"
        style={{ marginRight: '2em' }}
        onClick={() => {
          setTableedit(false);
        }}
      >
        Back
      </button>
      <button
        type="button"
        className="btn btn-dark mt-2"
        // disabled={!selectedVideos || selectedVideos.length === 0}
        onClick={nextStep}
      >
        Next
      </button>
    </div>
  );
};

const Step2 = ({
  selectedVideos,
  setSelectedVideos,
  onPlayListSUbmit,
  isPortrait,
  isLandscape,
  getPlalists,
}) => {
  const { previousStep } = useWizard();

  return (
    <div>
      <ManageVideos
        selectedVideos={selectedVideos}
        setSelectedVideos={setSelectedVideos}
        isPortrait={isPortrait}
        isLandscape={isLandscape}
      />
      <button className="btn btn-light m-1" onClick={previousStep}>
        Back
      </button>
      <button
        className="btn btn-dark m-1"
        // disabled={!selectedVideos || selectedVideos.length === 0}
        onClick={onPlayListSUbmit}
      >
        Update
      </button>
    </div>
  );
};

function extractFieldAndJoin(array, fieldName) {
  if (array.length === 0) {
    return '';
  }
  const values = array.map((obj) => obj[fieldName]);
  const result = values.join(',');
  return result;
}

const PlaylistEdit = ({ getPlalists, tab, setTab }) => {
  const [filterProduct, setFilterProduct] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [title, setTitle] = useState('');
  const [layout, setLayout] = useState('layout1');
  const [orientation, setOrientation] = useState('Portrait');
  const [landscapevideoData, setLandscapevideoData] = useState([]);
  const [portraitvideoData, setPortraitvideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [landscameRowsCount, setLandscameRowsCount] = useState(0);
  const [portraitRowsCount, setPortraitRowsCount] = useState(0);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [portraitOffset, setPortraitOffset] = useState(1);
  const [landscapeOffset, setLandscapeOffset] = useState(1);
  const [landScapeViewMoreLoading, setLandScapeViewMoreLoading] =
    useState(false);
  const [portraitViewMoreLoading, setPortraitViewMoreLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [playlistObj, setPlaylistObj] = useState({});
  const [playListData, setPlalistData] = useState({
    shortCode: user.short_code,
    playlistCode: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const playlistDataId = searchParams.get('playlistDataId');
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const playlistcodeModalOpen = (row) => {
    setModal(true);
    setPlalistData({
      shortCode: user.short_code,
      playlistCode: row?.playlistCode,
      onCreatePlaylist: row.onCreatePlaylist,
    });
  };

  const getVideoObjs = (selectedCards) => {
    const portraitVideosData = portraitvideoData.filter((video) =>
      selectedCards.includes(video.video_id)
    );
    const landscapeVideosData = landscapevideoData.filter((video) =>
      selectedCards.includes(video.video_id)
    );
    const data = portraitvideoData.map((el) => {
      return selectedCards.includes(el.video_id);
    });

    setSelectedVideos(
      portraitVideosData.length > 0 ? portraitVideosData : landscapeVideosData
    );
    return portraitVideosData.length > 0
      ? portraitVideosData
      : landscapeVideosData;
  };

  const filterOrientation = selectedVideos[0]?.is_landscape
    ? selectedVideos[0]?.is_landscape
    : 1;

  const fetchData = async () => {
    setLoading(true);
    try {
      await allApiInstance
        .get(
          `/getVideosByVideoOrientationAndBrandId?brandId=${user.brand_id}&videoOrientation=1`
        )
        .then((res) => {
          setLoadingOverlay(true);
          if (res.data) {
            setPortraitvideoData(res.data.swirls);
            setTimeout(() => {
              setLoadingOverlay(false);
            }, 500);
          }
        });
      await allApiInstance
        .get(
          `/getVideosByVideoOrientationAndBrandId?brandId=${user.brand_id}&videoOrientation=0`
        )
        .then((res) => {
          setLoadingOverlay(true);
          if (res.data) {
            setLandscapevideoData(res.data.swirls);
            setTimeout(() => {
              setLoadingOverlay(false);
            }, 500);
          }
        });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchData2 = async () => {
    try {
      const [landscapeRes, portraitRes] = await Promise.all([
        allApiInstance.get(
          `/getAllLandscapeVideoByBrandId?brandId=${user.brand_id}&searchQuery=${queryText}&productId=${filterProduct.productId}`
        ),
        allApiInstance.get(
          `/getAllPortraitVideoByBrandId?brandId=${user.brand_id}&searchQuery=${queryText}&productId=${filterProduct.productId}`
        ),
      ]);
      setLandscapevideoData(landscapeRes.data.swirls);
      setPortraitvideoData(portraitRes.data.swirls);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData2();
  }, [filterProduct, queryText]);

  const fetchPlaylistData = async (playlistDataId) => {
    // setLoading(true);
    try {
      // Simulated playlist data

      await allApiInstance
        .get(
          `/getPlaylistInfoByPlaylistId?brandId=${user.brand_id}&playlistId=${playlistDataId}`
        )
        .then((res) => {
          if (res.data.playlist) {
            setPlaylistObj(res.data.playlist);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (playlistObj?.id) {
      // Fetch the video objects based on the IDs
      const videoIds = playlistObj?.videoId.split(',');
      const selectids = videoIds.map((id) => {
        return parseInt(id);
      });
      //  const videoObjects = await Promise.all(videoIds.map(id => allApiInstance.get(`/getVideoById?videoId=${id}`).then(res => res.data.video)));
      // alert("playlistobj")
      getVideoObjs(selectids);

      // setSelectedVideos(videoObjects);
      setTitle(playlistObj.name);
    }
  }, [playlistObj]);

  useEffect(() => {
    fetchData();
  }, [filterOrientation]);

  useEffect(() => {
    if (playlistDataId && (landscapevideoData || portraitvideoData)) {
      fetchPlaylistData(playlistDataId);
      // alert("PLAYLIST")
    }
  }, [playlistDataId, landscapevideoData, portraitvideoData]);

  useEffect(() => {
    // alert("!")
    if (selectedVideos.length > 0) {
      if (selectedVideos[0].is_landscape == 1) {
        setIsPortrait(true);
        setIsLandscape(false);
      } else {
        setIsPortrait(false);
        setIsLandscape(true);
      }
    } else {
      setIsPortrait(true);
    }
  }, [selectedVideos]);

  const onPlayListSUbmit = async () => {
    try {
      await allApiInstance
        .patch('/editPlaylistByPlaylistId', {
          payload: {
            id: playlistDataId,
            videoId: extractFieldAndJoin(selectedVideos, 'video_id'),
            playlistName: title,
            brandId: user.brand_id,
          },
        })
        .then((res) => {
          if (res.data) {
            toast.success('Playlist edited');
            playlistcodeModalOpen({
              shortCode: user.short_code,
              playlistCode: playlistObj.playlistCode,
              onCreatePlaylist: true,
            });
            getPlalists();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Something went wrong!');
        });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
  };

  if (loading) {
    return (
      <div
        className="w-100 d-grid"
        style={{ placeItems: 'center', height: '70vh' }}
      >
        <FallingLines
          color="#EEEE77"
          width="100"
          visible={true}
          ariaLabel="falling-circles-loading"
        />
      </div>
    );
  }

  const setTableedit = (value) => {
    searchParams.set('Tableedit', value);
    navigate({ search: searchParams.toString() });
  };

  return (
    <div className="container-fluid ">
      <p className="text-secondary ml-2 mb-0">
        <span
          className="pointer_cursor"
          onClick={() => {
            setTableedit(false);
          }}
        >
          Playlist
        </span>
        <span style={{ marginLeft: '0.7em', marginRight: '0.7em' }}> &gt;</span>{' '}
        <span className="text-primary">Edit Playlist</span>
      </p>
      {/* <div className='container text-center'>
                <div className="row justify-content-center mb-2">
                    <div className="col-3">
                        <div className={`step-number ${currentStep >= 0 ? 'bg-eeee77' : ''}`}>1</div>
                        <span className={`fw-light ${currentStep >= 0 ? 'fw-bold' : ''}`}>Add Videos </span>
                        <span style={{ paddingLeft: '25%' }}> &gt; </span>
                    </div>
                    <div className="col-3">
                        <div className={`step-number ${currentStep >= 1 ? 'bg-eeee77' : ''}`}>2</div>
                        <span className={`fw-light ${currentStep >= 0 ? 'fw-bold' : ''}`}>Manage Videos </span>
                    </div>
                </div>
            </div> */}
      <div className="container text-center">
        <div className="row justify-content-center mb-1">
          <div className="col-2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`step-number ${currentStep >= 0 ? 'bg-eeee77' : ''}`}
              >
                1
              </div>
              <span
                className={`fw-bold ${currentStep >= 0 ? 'fw-bold-active' : ''}`}
              >
                Add Videos
              </span>
            </div>
            <MdOutlineKeyboardArrowRight
              size={22}
              className="ms-2 arrow-center"
            />
          </div>
          <div className="col-2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`step-number ${currentStep >= 1 ? 'bg-eeee77' : ''}`}
              >
                2
              </div>
              <span className={`fw-light ${currentStep >= 1 ? 'fw-bold' : ''}`}>
                Manage Videos
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-1 mt-0" />
      <Wizard onStepChange={(step) => setCurrentStep(step)}>
        <Step1
          setSelectedVideos={setSelectedVideos}
          selectedVideos={selectedVideos}
          landscapevideoData={landscapevideoData}
          portraitvideoData={portraitvideoData}
          isPortrait={isPortrait}
          isLandscape={isLandscape}
          // viewMoreLandscape={viewMoreLandscape}
          // viewMorePortrait={viewMorePortrait}
          setTableedit={setTableedit}
          setPortraitOffset={setPortraitOffset}
          setLandscapeOffset={setLandscapeOffset}
          setQueryText={setQueryText}
          fetchData={fetchData}
          fetchData2={fetchData2}
          filterProduct={filterProduct}
          queryText={queryText}
          setFilterProduct={setFilterProduct}
          loadingOverlay={loadingOverlay}
          setLoadingOverlay={setLoadingOverlay}
          tab={tab}
          setTab={setTab}
        />
        <Step2
          setSelectedVideos={setSelectedVideos}
          selectedVideos={selectedVideos}
          onPlayListSUbmit={onPlayListSUbmit}
          isPortrait={isPortrait}
          isLandscape={isLandscape}
        />
      </Wizard>
      <PlaylistcodeModal
        getPlalists={getPlalists}
        show={modal}
        handleClose={() => setModal(false)}
        playListData={playListData}
      />
    </div>
  );
};

export default PlaylistEdit;
